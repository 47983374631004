<template><Logo/></template>

<script>
export default {
  name: 'IconeCadenas',
  components: {
    Logo: () => import('./inline.cadenas.svg'),
  },
}
</script>
